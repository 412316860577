/** 获取本地存储 */
export const getLocalStorage = (key) => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key))
  }
}
/** 设置本地存储 */
export const setLocalStorage = (key, val) => {
  return localStorage.setItem(key, JSON.stringify(val))
}
/** 删除本地存储 */
export const removeLocalStorage = (key) => {
  return localStorage.removeItem(key)
}

export const getSessionStorage = (key) => {
  if (sessionStorage.getItem(key)) {
    return JSON.parse(sessionStorage.getItem(key))
  }
}
export const setSessionStorage = (key, val) => {
  return sessionStorage.setItem(key, JSON.stringify(val))
}
export const removeSessionStorage = (key) => {
  return sessionStorage.removeItem(key)
}

export default {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  getSessionStorage,
  setSessionStorage,
  removeSessionStorage,
}
