import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/login.vue'),
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import('@/layout/layout.vue'),
    children: [
      {
        path: '/category',
        name: 'category',
        component: () =>
          import(/* webpackChunkName: "order" */ '@/views/category/index'),
        meta: {
          title: '分级管理',
          parentLevel: 101,
          keepAlive: true,
        },
      },
      {
        path: '/checkCategory',
        name: 'checkCategory',
        component: () =>
          import(
            /* webpackChunkName: "order" */ '@/views/category/checkCategory'
          ),
        meta: {
          title: '查看分级',
          parentLevel: 101,
          keepAlive: false,
        },
      },
      {
        path: '/checkdeepCategory',
        name: 'checkdeepCategory',
        component: () =>
          import(
            /* webpackChunkName: "order" */ '@/views/category/checkdeepCategory'
          ),
        meta: {
          title: '查看深层分级',
          parentLevel: 101,
          keepAlive: true,
        },
      },
      {
        path: '/res',
        name: 'res',
        component: () =>
          import(/* webpackChunkName: "order" */ '@/views/res/index'),
        meta: {
          title: '资源管理',
          parentLevel: 102,
          keepAlive: false,
        },
      },
      {
        path: '/upload',
        name: 'upload',
        component: () =>
          import(/* webpackChunkName: "order" */ '@/views/res/uploadRes'),
        meta: {
          title: '资源上传',
          parentLevel: 102,
          keepAlive: true,
        },
      },
      {
        path: '/modifyRes',
        name: 'modifyRes',
        component: () =>
          import(/* webpackChunkName: "order" */ '@/views/res/modifyRes'),
        meta: {
          title: '资源修改',
          parentLevel: 102,
          keepAlive: true,
        },
      },
      {
        path: '/users',
        name: 'users',
        component: () =>
          import(/* webpackChunkName: "order" */ '@/views/users/index'),
        meta: {
          title: '用户管理',
          parentLevel: 103,
          keepAlive: true,
        },
      },

      {
        path: '/personalSetting',
        name: 'personalSetting',
        component: () =>
          import(
            /* webpackChunkName: "personal" */ '@/views/personalSetting/personalSetting.vue'
          ),
        meta: {
          title: '个人设置',
          parentLevel: 11,
        },
      },
      {
        path: '/administratorList',
        name: 'administratorList',
        component: () =>
          import(
            /* webpackChunkName: "role" */ '@/views/administratorSet/administratorList'
          ),
        meta: {
          title: '管理员列表',
          parentLevel: 999,
        },
      },
      {
        path: '/jurisdiction',
        name: 'jurisdiction',
        component: () =>
          import(
            /* webpackChunkName: "role" */ '@/views/administratorSet/jurisdiction'
          ),
        meta: {
          title: '权限管理',
          parentLevel: 999,
        },
      },
      {
        path: '/role',
        name: 'role',
        component: () =>
          import(
            /* webpackChunkName: "role" */ '@/views/administratorSet/role'
          ),
        meta: {
          title: '角色管理',
          parentLevel: 999,
        },
      },
      {
        path: '/operationNote',
        name: 'operationNote',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/administratorSet/operationNote'
          ),
        meta: {
          title: '日志管理',
          parentLevel: 999,
        },
      },
      {
        path: '/banner',
        name: 'banner',
        component: () =>
          import(/* webpackChunkName: "banner" */ '@/views/banner/index'),
        meta: {
          title: '广告管理',
          parentLevel: 104,
        },
      },
      {
        path: '/recommend',
        name: 'recommend',
        component: () =>
          import(/* webpackChunkName: "recommend" */ '@/views/recommend/index'),
        meta: {
          title: '推荐管理',
          parentLevel: 105,
        },
      },
      {
        path: '/recommendRes',
        name: 'recommendRes',
        component: () =>
          import(
            /* webpackChunkName: "recommendRes" */ '@/views/recommend/resource'
          ),
        meta: {
          title: '资源推荐',
          parentLevel: 105,
        },
      },
      {
        path: '/recommendBook',
        name: 'recommendBook',
        component: () =>
          import(
            /* webpackChunkName: "recommendBook" */ '@/views/recommend/newBook'
          ),
        meta: {
          title: '新上书籍',
          parentLevel: 105,
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
