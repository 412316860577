/**
 * 接口域名的管理
 * @param {String} apiServer [api服务器]
 * @param {String} knobbleServer [小节内容上传服务器]
 */
const baseLsit =[
    {    
        apiServer: 'http://10.0.30.117:9989',//开发服务器(任) 0
        knobbleServer: 'http://10.0.30.117/section',//开发服务器
        // knobbleServer: 'http://10.0.30.109:8199',//开发服务器
    },
    {    
        apiServer: 'http://10.0.30.109:9989',//开发服务器(李) 1
        knobbleServer: 'http://10.0.30.109/section',//开发服务器
        // knobbleServer: 'http://10.0.30.109:8199',//开发服务器
    },
    {
        apiServer: 'https://server.enworld-eres.boran-tech.com',//正式服务器 2
        knobbleServer:"https://server.enworld-eres.boran-tech.com/section"//正式服务器
    }
]
const ServerNumber = process.env.VUE_APP_SERVER_ID?process.env.VUE_APP_SERVER_ID:0;
const base = baseLsit[ServerNumber];

export default base;

