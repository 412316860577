import { createStore } from 'vuex'
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils'

export default createStore({
  state: {
    userInfo: {},
    token: '',
  },
  getters: {
    userInfo: (state) => state.userInfo,
    token: (state) => state.token,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      setLocalStorage('userInfo', userInfo)
    },
    setToken(state, token) {
      state.token = token
    },
    initUserInfo(state) {
      state.userInfo = {}
      removeLocalStorage('userInfo')
    },
    initToken(state) {
      state.token = ''
    },
    logOut(state) {
      state.userInfo = {}
      state.token = ''
      removeLocalStorage('userInfo')
    }
  },
  actions: {},
  modules: {},
})
